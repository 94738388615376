<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">查看报警数据 - 发生时间: {{item.startTime}}</div>
    <div class="search-data-header">
      <div class="analyse-table">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td style="width: 100px">所在分区</td>
            <td>{{item.groupName}}</td>
            <td style="width: 100px">发生时间</td>
            <td>{{item.startTime}}</td>
            <td style="width: 100px">消除时间</td>
            <td>{{item.doneTime || '-'}}</td>
          </tr>
          <tr>
            <td>报警设备</td>
            <td>{{item.lampName}}[{{ item.lampCode }}]</td>
            <td>关联网关</td>
            <td>{{item.gatewayName}}[{{ item.gatewayCode }}]</td>
            <td>报警类型</td>
            <td style="color:#f00">{{item.name}}</td>
          </tr>
          <tr>
            <td>故障现象</td>
            <td colspan="5" style="color:#f00">{{item.extra}}</td>
          </tr>
          <tr>
            <td>故障确认</td>
            <td colspan="5">{{item.isConfirm ? '已确认' : '未确认'}}
              <Button v-if="funCodes(4101) && !item.isConfirm && !item.doneTime" style="margin-right: 0; float:right;" size="default" type="primary" @click="confirmAlarm">确认告警</Button>
              <span v-if="item.isConfirm">: 由 {{item.byUser ? '用户' : '工单系统'}} [{{item.confirmName}}] 确认于 [{{item.confirmTime}}]</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="log-search-area">
      {{ showLang('com.date.start') }}：
      <DatePicker v-model="filter.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      {{ showLang('com.date.end') }}：
      <DatePicker v-model="filter.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      <Button size="default" type="primary" style="margin-right: 5px" @click="getList">{{ showLang('com.op.query') }}</Button>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" stripe round
      :export-config="{}" :data="history" height="auto" :row-config="{isHover: true, height: rowHeight}"
      :loading="loading">
        <vxe-column field="lampName" :title="showLang('com.tab.title')" fixed="left" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="lampCode" :title="showLang('com.export.cn.id')" fixed="left" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.date.time')" width="150" fixed="left"></vxe-column>
        <vxe-column v-if="rowHeight > 48" title="通道号" width="70" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">通道一</div>
              <div v-if="params.row.enable2" class="split-row-item">通道二</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="output1" title="亮度(%)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.output1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.output2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="color1" title="色温(%)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.color1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.color2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="voltage" title="电压(V)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.voltage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.voltage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="current" title="电流(A)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.current1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.current2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="power2" title="功率(W)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.power1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.power2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="powerOn1" title="累计亮灯(分)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.powerOn1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.powerOn2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="quality1" title="累计能耗(W)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.quality1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.quality2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="voltageLeakage1" title="漏电电压(V)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.voltageLeakage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.voltageLeakage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="currentLeakage1" title="漏电电流(mA)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.currentLeakage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.currentLeakage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="devModeName1" title="控制方式" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.devModeName1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.devModeName2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="rssi" title="信号强度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="tempChip" title="芯片温度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="tempModule" title="模块温度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="batterySun" title="电池电压" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="lightGroupName" title="所在分组" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="lightGroupCode" title="分组号" width="70" header-align="center"></vxe-column>
        <vxe-column field="gatewayName" title="关联网关" width="150" header-align="center"></vxe-column>
        <vxe-column field="gatewayCode" title="网关ID" width="100" header-align="center"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalAlarmData',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showCompareModal: false,
      showModal: this.value,
      loading: false,
      filter: {
        code: '',
        start: '',
        end: '',
        index: 1,
        size: 20,
      },
      total: 0,
      rowHeight: 48,
      alarmTs: 0,
      history: [],
      searchLight: {},
      lightConfig: {},
      lightName: '',
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        let time = new Date(this.item.startTime);
        this.alarmTs = time.getTime();
        this.filter.code = this.item.lampCode;
        time.setMinutes(time.getMinutes() - 65);
        this.filter.start = time.format('yyyy-MM-dd HH:mm:ss');
        time.setMinutes(time.getMinutes() + 130);
        this.filter.end = time.format('yyyy-MM-dd HH:mm:ss');
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['pageLayouts', 'pageSizes', 'coms6038names']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
  },
  mounted: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    showCompareLogs: function(){
      this.showCompareModal = true;
    },
    imgListAjax(id) {
      return `//${this.domains.trans}/station/file/${id}`;
    },
    confirmAlarm: function(){
      this.$Modal.confirm({
        title: '确认告警',
        content: `确定已知道该告警吗？`,
        onOk: async () => {
          this.$axios.post(`station/alarm/ConfirmLightAlarm`, {id: this.item.id}).then(res => {
            if(res.code == 0){
              if(!res.data.success){
                this.$Modal.error({
                  title: '确认失败',
                  content: res.data.message,
                });
                return;
              }
              this.item.byUser = res.data.byUser;
              this.item.confirmId = res.data.confirmId;
              this.item.confirmName = res.data.confirmName;
              this.item.confirmTime = res.data.confirmTime;
              this.item.isConfirm = res.data.isConfirm;
            }
          });
        }
      });
    },
    getList() {
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.$axios.post(`device/light/QueryLightLogs`, this.filter).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'history', res.data.list);
          this.total = res.data.count;
          let muti = false;
          for(let item of res.data.list){
            if(item.enable1 && item.enable2){
              muti = true;
              break;
            }
          }
          this.rowHeight = muti ? 60 : 48;
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.log-search-area{
  /* border: solid 1px red; */
  height: 50px;
}
.alarm-color {
  color: red;
}
td {
  height: 40px;
  /* white-space: nowrap; */
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-content {
  height: 400px;
  flex: auto;
  /* border: solid 1px blue; */
  overflow: auto;
}
.analyse-table table {
  width: 100%;
  height: 93px;
  margin-bottom: 20px;
}
.analyse-table table td {
  /* width: 115px; */
  /* text-align: center; */
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  padding: 0 10px;
}
.showImgBox {
  width: 300px;
  height: 300px;
  border: 1px solid #b7afaf;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
.showImgBox img {
  max-width: 297px;
  max-height: 297px;
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>